import React from 'react';

import EmailVerificationSuccessTemplate from '../templates/EmailVerificationSuccessTemplate';

const EmailVerificationSuccessPage = () => (
  <EmailVerificationSuccessTemplate
    pageContext={{ pagePath: '/email-verification-success' }}
  />
);

export default EmailVerificationSuccessPage;

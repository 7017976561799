import React from 'react';

import InlineBlockWrapper from '../../../components/InlineBlockWrapper';

import * as styles from './index.module.css';

const RegistrationSteps = ({ currentStep }) => {
  const currentStepClassName = `${styles['cardWithStepNumber']} ${styles['isCurrent']}`;

  return (
    <div>
      <ul className={styles['list']}>
        <li className={styles['listItem']}>
          <div
            className={
              currentStep === 1
                ? currentStepClassName
                : styles['cardWithStepNumber']
            }
          >
            <InlineBlockWrapper className={styles['stepNumberWrapper']}>
              <span className={styles['stepNumber']}>Step1</span>
            </InlineBlockWrapper>

            <div className={styles['card']}>
              <InlineBlockWrapper className={styles['labelWrapper']}>
                <span className={styles['label']}>
                  利用規約
                  <br />
                  の確認
                </span>
              </InlineBlockWrapper>
            </div>
          </div>
        </li>

        <li className={styles['listItemDivider']}></li>

        <li className={styles['listItem']}>
          <div
            className={
              currentStep === 2
                ? currentStepClassName
                : styles['cardWithStepNumber']
            }
          >
            <InlineBlockWrapper className={styles['stepNumberWrapper']}>
              <span className={styles['stepNumber']}>Step2</span>
            </InlineBlockWrapper>

            <div className={styles['card']}>
              <InlineBlockWrapper className={styles['labelWrapper']}>
                <span className={styles['label']}>
                  メールアドレス
                  <br />
                  の確認
                </span>
              </InlineBlockWrapper>
            </div>
          </div>
        </li>

        <li className={styles['listItemDivider']}></li>

        <li className={styles['listItem']}>
          <div
            className={
              currentStep === 3
                ? currentStepClassName
                : styles['cardWithStepNumber']
            }
          >
            <InlineBlockWrapper className={styles['stepNumberWrapper']}>
              <span className={styles['stepNumber']}>Step3</span>
            </InlineBlockWrapper>

            <div className={styles['card']}>
              <InlineBlockWrapper className={styles['labelWrapper']}>
                <span className={styles['label']}>
                  プロフィール
                  <br />
                  の登録
                </span>
              </InlineBlockWrapper>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default RegistrationSteps;

import React from 'react';
import 'ress/ress.css';
import '../../styles/variables.css';
import '../../styles/base.css';

import PublicRoot from '../../components/PublicRoot';
import SEO from '../../components/SEO';
import PageDefault from '../../components/PageDefault';
import ContentWrapper from '../../components/ContentWrapper';

import SiteHeader from '../../organisms/SiteHeader';
import SiteFooter from '../../organisms/SiteFooter';
import GlobalNavigation from '../../organisms/GlobalNavigation';

import RegistrationSteps from '../../molecules/common/RegistrationSteps';

import * as styles from './index.module.css';

const EmailVerificationSuccessTemplate = ({ pageContext }) => {
  const { pagePath } = pageContext;

  return (
    <PublicRoot>
      <SEO path={pagePath} title="メールアドレスの確認が完了しました" />
      <SiteHeader />

      <PageDefault>
        <ContentWrapper>
          <main className={styles['main']}>
            <section>
              <div className={styles['container']}>
                <div className={styles['contentArea']}>
                  <div className={styles['headingWrapper']}>
                    <h2 className={styles['heading']}>
                      <span className={styles['headingMain']}>
                        TORCH無料会員登録
                      </span>
                    </h2>
                  </div>

                  <div className={styles['registrationStepsSectionWrapper']}>
                    <section className={styles['registrationStepsSection']}>
                      <h2 className="sr-only">無料会員登録3ステップ</h2>

                      <div className={styles['registrationStepsWrapper']}>
                        <RegistrationSteps currentStep={2} />
                      </div>
                    </section>
                  </div>

                  <div className={styles['subHeadingWrapper']}>
                    <h1 className={styles['subHeading']}>
                      <span className={styles['subHeadingMain']}>
                        メールアドレスの確認が完了しました
                      </span>
                    </h1>
                  </div>

                  <div className={styles['paragraphWrapper']}>
                    <p className={styles['paragraph']}>
                      メーラーから開いたブラウザーなどでメールアドレスを確認いただいた場合、ログインしていない状態になっていることがあります。
                      <br />
                      登録いただいたブラウザーの画面からホームに戻り、プロフィールの登録をお願いいたします。
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </ContentWrapper>
      </PageDefault>

      <GlobalNavigation />

      <SiteFooter />
    </PublicRoot>
  );
};

export default EmailVerificationSuccessTemplate;
